import React, { useEffect } from "react";
import NavbarWithDrawer from "./components/NavbarWithDrawer";
import Footer from "./components/footer";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PrivateRoutes from "./private/route";
import AcceptableUsePage from "./pages/footer/AcceptableUsePage";
import CookiePolicyPage from "./pages/footer/CookiePolicyPage";
import CancellationPage from "./pages/footer/CancellationPage";
import StepByStepGuidePage from "./pages/footer/StepByStepGuidePage";
import SupportPage from "./global/components/SupportPage";
import Guidelines from "./global/components/Guidelines";
import PrivacyPolicyPage from "./pages/footer/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/footer/TermsOfServicePage";
import { useLocation } from "react-router-dom";
import PublicRoutes from "./pages/route";

function App() {
  window.history.scrollRestoration = "manual";
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <NavbarWithDrawer />
      <Routes>
        <Route path="/*" element={<PublicRoutes />} />
        <Route path="/user/*" element={<PrivateRoutes />} />
      </Routes>
    </div>
  );
}

export default App;
