import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import hero from "../../src/assets/img/hero.png";
import { mfaData } from "../../src/data";

function Hero() {
  const whatsapp = mfaData.whatsapp;
  const msg = mfaData.msg;
  return (
    <Box
      id="home"
      // paddingX={8}
      paddingTop={8}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "white",
      }}
      style={{ scrollMarginTop: "60px" }}
    >
      <Container maxWidth="lg" marginTop={"60px"}>
        <Grid container spacing={4}>
          <Grid
            item
            container
            xs={12}
            md={6}
            alignItems={"center"}
            display="flex"
            justifyContent="center"
          >
            <Box>
              <Box marginBottom={4}>
                <Typography
                  variant="h1"
                  color="primary.main"
                  sx={{
                    animation: "slideInFromLeft 1s ease",
                    "@keyframes slideInFromLeft": {
                      "0%": {
                        transform: "translateX(-100%)",
                        opacity: 0,
                      },
                      "100%": {
                        transform: "translateX(0)",
                        opacity: 1,
                      },
                    },
                  }}
                >
                  {mfaData.heroTitle}
                </Typography>
                <Typography
                  variant="h5"
                  color="grey"
                  marginTop={3}
                  sx={{
                    animation: "slideInFromLeft 1s ease",
                    "@keyframes slideInFromLeft": {
                      "0%": {
                        transform: "translateX(-50%)",
                        opacity: 0,
                      },
                      "100%": {
                        transform: "translateX(0)",
                        opacity: 1,
                      },
                    },
                  }}
                >
                  {mfaData.heroSubtitle}
                </Typography>
              </Box>

              <Box marginBottom={2}>
                <Button
                  variant="contained"
                  size="large"
                  style={{ color: "white" }}
                  // sx={{ fontSize: "16px", fontWeight: "700" }}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    animation: "slideUp 1.5s ease",
                    "@keyframes slideUp": {
                      "0%": {
                        transform: "translateY(50px)",
                        opacity: 0,
                      },
                      "100%": {
                        transform: "translateY(0)",
                        opacity: 1,
                      },
                    },
                  }}
                  href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=${encodeURIComponent(
                    msg
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get in touch
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            xs={12}
            md={6}
            paddingBottom={4}
          >
            <Box
              component={"img"}
              height={1}
              width={1}
              marginTop={2}
              marginBottom={2}
              src={hero}
              alt="..."
              borderRadius={2}
              maxWidth={600}
              maxHeight={500}
              display="flex"
              sx={{
                "@keyframes fadeIn": {
                  "0%": {
                    opacity: 0,
                    // border: '2px solid #eee', // Border when opacity is 0
                  },
                  "100%": {
                    opacity: 1,
                    // border: 'none', // No border when opacity is 1
                  },
                },
                animation: "fadeIn 0.4s ease-in-out 0.4s forwards",
                opacity: 0,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Hero;
