

import React from "react";

import { Box, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { WEBSITE_NAME } from "../../data";


export default function StepByStepGuidePage() {
  

  const name = WEBSITE_NAME;
  // const web = ' www.sheetstowhatsapp.com';
  // const effectiveDate = '29 July 2023';

  return (
    <>
    

      <Box marginTop={16} marginBottom={16}>
        <Container maxWidth="md">
          <Typography
            fontWeight={700}
            variant="h1"
            align="center"
            marginTop={4}
          >
            Step by step guide
          </Typography>
          <Typography variant="body1" color="grey" marginTop={4}>
            We are committed to providing the best customer support and
            assistance to ensure your experience with our products and services
            is seamless and satisfying. If you have any queries, concerns, or
            need assistance with any aspect of our website, please refer to the
            following support options:
          </Typography>

          <Typography fontWeight={700} variant="h6" marginTop={4}>
            Frequently Asked Questions (FAQs):
          </Typography>
          <Typography variant="body1" color="grey" marginTop={4}>
            Before reaching out to our support team, check our comprehensive
            FAQs section, where you can find answers to commonly asked
            questions. We have covered various topics to provide quick solutions
            to your queries.
          </Typography>
          <Typography fontWeight={700} variant="h6" marginTop={4}>
            Email Support:
          </Typography>
          <Typography variant="body1" color="grey" marginTop={4}>
            For any queries, you can also email us directly at
            samarth8625@gmail.com. Our support team will respond to your email
            as soon as possible.
          </Typography>

          <Typography variant="body1" color="grey" marginTop={4}>
            At {name}, we value your satisfaction, and our support team is
            dedicated to ensuring that you have a pleasant experience with us.
            We look forward to assisting you and addressing any concerns you may
            have. Your feedback is crucial to us as we continuously strive to
            improve our services to meet your needs. Thank you for choosing{" "}
            {name}, and we are here to support you every step of the way.
          </Typography>
        </Container>
      </Box>
    </>
  );
}
