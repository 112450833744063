import React, { useEffect, useRef, useState } from "react";
import { Box, Card, Avatar, Grid, Typography, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stats } from "../../src/data";

import { motion } from "framer-motion";
const data = stats;

function Stats() {
  const theme = useTheme();
  return (
    <Box bgcolor={"white"} paddingY={4}>
      <Container maxWidth="lg">
        <Box>
          <Typography
            variant={"h3"}
            sx={{ fontWeight: 700 }}
            align={"center"}
            gutterBottom
          >
            We thrive on trust!
          </Typography>
          <Typography
            variant={"body1"}
            align={"center"}
            color={"text.secondary"}
          >
            And you don't need to just take our word for it. We manage...
          </Typography>
        </Box>
        <Grid
          container
          display={"flex"}
          marginTop={4}
          sx={{ gap: { xs: 2, sm: 0, md: 0, lg: 0 } }}
        >
          {data.map((item, i) => (
            <Grid
              key={i}
              item
              xs={12}
              md={4}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                component={Card}
                display={"flex"}
                paddingX={4}
                paddingY={2}
                width={0.7}
                // height={1}
                variant={"outlined"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    component={Avatar}
                    width={50}
                    height={50}
                    marginBottom={2}
                    bgcolor={theme.palette.primary.main}
                    color={theme.palette.background.paper}
                  >
                    <FontAwesomeIcon icon={item.iconName} />
                  </Box>
                  {/* <Typography
                    variant="h2"
                    align={"center"}
                    fontWeight={700}
                    color="primary.main"
                    gutterBottom
                  > */}
                  <Counter limit={item.title} />

                  {/* </Typography> */}
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align={"center"}
                    component="p"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
const Counter = ({ limit }) => {
  const ref = useRef(null);
  const [count, setCount] = useState(limit === "1500+" ? 1450 : 0);

  const [isInView, setIsInView] = useState(false);

  console.log(">>>>>>>>>>>>>>", isInView);
  useEffect(() => {
    console.log("Element is in view: ", isInView);
  }, [isInView]);
  let limit2;
  if (limit === "5⭐") {
    limit2 = 5;
  } else if (limit === "15 Years") {
    limit2 = 15;
  } else if (limit === "1500+") {
    limit2 = 1500;
  } else {
    limit2 = 0;
  }

  useEffect(() => {
    if (isInView) {
      const interval = setInterval(
        () => {
          if (count < limit2) {
            setCount((prevCount) => prevCount + 1);
          } else {
            clearInterval(interval);
          }
        },
        limit2 === 1500 ? 10 : 50
      );

      return () => clearInterval(interval);
    }
  }, [count, isInView, limit2]);

  console.log("check", count, limit2);

  return (
    <motion.div
      whileInView={() => {
        // when element in viewport , set IsInView true!
        setIsInView(true);
        return {};
      }}
      viewport={{ once: true }}
    >
      {" "}
      <Typography
        variant="h2"
        align="center"
        fontWeight={700}
        color="primary.main"
        gutterBottom
        ref={ref}
      >
        {`${count}${limit === "15 Years" ? " Years" : ""}${
          limit === "5⭐" ? "⭐" : ""
        }${limit === "1500+" ? "+" : ""}`}
      </Typography>
    </motion.div>
  );
};
export default Stats;
