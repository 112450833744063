import {
  faUsers,
  faCalendarCheck,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import benefits_advise from "../src/assets/img/benefits_advise.png";
import benefits_security from "../src/assets/img/benefits_security.png";
import benefits_goals from "../src/assets/img/benefits_goals.png";
import benefits_redeem from "../src/assets/img/benefits_redeem.png";

export const mfaData = {
  heroTitle: "Let's grow your wealth, together",
  heroSubtitle: "World Class Wealth Management Advisory",
  phone: "9004424074",
  whatsapp: "917039604776",
  msg: "Hello, I liked your offering, Can we have a discussion?",
  brandName: "Money Dost Financial Advisors",
  address: "11, JK Road, Sector E, Bhopal, Madhya Pradesh 462023",
};

export const stats = [
  {
    iconName: faUsers,
    title: "1500+",
    subtitle: "Customers trust us to manage their money.",
  },
  {
    iconName: faCalendarCheck,
    title: "15 Years",
    subtitle: "Helping our customers to achieve their financial goals.",
  },
  {
    iconName: faHeart,
    title: "5⭐",
    subtitle: "99% of our customers rated our services 5-star.",
  },
];

export const benefits = [
  {
    title: "Unbiased Advise",
    subtitle:
      "Our top priority is to provide unbiased advice to our clients. We remain transparent throughout the process, ensuring our clients make informed decisions.",
    image: benefits_advise,
  },
  {
    title: "Data Security",
    subtitle:
      "We prioritize data security to protect our clients sensitive information. We utilize advanced encryption and data protection measures to secure our clients data from unauthorized access, theft, or loss.",
    image: benefits_security,
  },
  {
    title: "Goals Driven",
    subtitle:
      "We create personalized investment plans that align with their goals,  whether it is saving for retirement, funding education, or building wealth taking into consideration their risk tolerance, time horizon, and other factors.",
    image: benefits_goals,
  },
  {
    title: "Book Profits",
    subtitle:
      "Our team of experts monitor the markets and analyze investment opportunities, enabling our clients to book profits at the right time. We create investment strategies that balance risk and reward",
    image: benefits_redeem,
  },
];
export const WEBSITE_NAME = " MoneyDost";
export const WEBSITE_ADDRESS = " www.moneydost.com";
export const UPDATE_DATE = " 26 June 2024";
