import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { mfaData } from '../../src/data';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        paddingTop: 4,
        paddingBottom: 2,
        px: 2,
        mt: '50px',
        backgroundColor: (theme) => theme.palette.grey[200],
      }}
    >
      <Container>
        <Typography variant="body1" align="center" color="grey" gutterBottom>
          Corporate Office : {mfaData.address}
        </Typography>
        <Typography
          variant="body2"
          align="center"
          color="grey"
          fontSize="14px"
          gutterBottom
        >
          Mutual Fund investments are subject to market risks. Please read all
          scheme related documents carefully before investing.
          <br />
          Past performance is not an indicator of future returns.
        </Typography>
        <Typography variant="body1" align="center" color="grey" gutterBottom>
          {mfaData.brandName}
          <br />
          {'© Copyright '} {new Date().getFullYear()}. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
