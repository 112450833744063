import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";


// import img from "../../assets/img/refer.png";

// import { getUserReferalInfo, getUserReferalLink } from "../../slice/userThunk";
import AccordianDetails from "../components/AccordianDetails";
import InviteStatus from "../components/InviteStatus";
import Gamification from "../components/Gamification";
// import { PLUGIN_URL } from "../../constants";
export default function ReferAndEarn() {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  // const { user, referalLink } = useSelector((store) => store.user);

  const [helpertext, setHelperText] = useState("");
  // const dispatch = useDispatch();
  const handelCopyLinkClick = () => {
    // dispatch(getUserReferalLink("getReferalLink"))
      // .then(() => {
      //   setIsLinkCopied(true);
      //   setTimeout(() => {
      //     setIsLinkCopied(false);
      //     setHelperText(
      //       " Invite Link in copied on your clipboard. Now share that withour friends"
      //     );
      //   }, 5000);
      // })
      // .catch((err) => {
      //   console.error("Failed to copy:", err);
      //   setHelperText("An error occur try again latter");
      // });
  };
  useEffect(() => {
    // if (user.user.phone) {
    //   dispatch(getUserReferalLink("getReferalLink"));
    // }
    // dispatch(getUserReferalInfo());
  }, []);
  return (
    <>
      <Container sx={{ paddingY: "1rem" }}>
        <Typography variant="h3" fontWeight={600} mb={6}>
          Rewards
        </Typography>
        <Stack>
          <Stack
            direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
            minHeight={"50vh"}
            width={"100%"}
            padding={"24px 3vw 20px 3vw"}
            bgcolor={"rgb(232,232,253)"}
            borderRadius={"18px"}
          >
            <Box
              width={{ lg: "60%" }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Typography variant="h4" mb={4}>
                Invite friends to SheetWA and <br></br> get free access to the
                paid plan!
              </Typography>
              {/* {user.user.phone && (
                <>
                  <Typography variant="subtitle1">
                  Get 15 days free when someone you invite signs up,<br></br> and another 15 days after they send the first message.
                  </Typography> */}

                  {/* <Typography variant="body1" ml={1} fontWeight={600}>
                    {user.user.phone}
                  </Typography> */}
                {/* </>
              )} */}

              <Stack direction={"row"} mt={4} gap={2}>
                {/* {user.user.phone ? ( */}
                  <>
                    {/* <PillShapeBtn
                      text={isLinkCopied ? "Link copied" : "Copy invite link"}
                      icon={
                        isLinkCopied ? <DoneIcon color="green" /> : <LinkIcon />
                      }
                      onClick={handelCopyLinkClick}
                    />
                    <PillShapeBtn
                      text={"Copy invite code"}
                      icon={<EmailIcon />}
                      onClick={() => {
                        navigator.clipboard.writeText(user?.user?.phone);
                      }}
                    /> */}
                  </>
                {/* ) : (
                  <>
                    <PillShapeBtn
                      text={"Try first"}
                      icon={<SendIcon />}
                      onClick={() => {
                        // window.open(PLUGIN_URL, "_blank");
                      }}
                    />
                  </>
                )} */}

                {/* <PillShapeBtn text={"Coming Soon"} disabled /> */}
              </Stack>
              {isLinkCopied && (
                <Typography variant="body1" mt={1} color={"gray"}>
                  Invite Link in copied on your clipboard. Now share that
                  withour friends
                </Typography>
              )}
            </Box>

            <Stack
              width={{lg:"40%"}}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                component={"img"}
                // src={img}
                height={"45vh"}
                sx={{
                  // animation: "upDownAnimation 1s infinite alternate", // applying animation
                  // "@keyframes upDownAnimation": {
                  //   // defining animation
                  //   "0%": { transform: "translateY(0)" },
                  //   "100%": { transform: "translateY(5px)" },
                  // },
                  borderRadius: "30px",
                }}
              ></Box>
            </Stack>
          </Stack>
          <Gamification />
          <InviteStatus />
          <AccordianDetails />
        </Stack>
      </Container>
    </>
  );
}
const PillShapeBtn = ({ icon, text, onClick, ...props }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      {...props}
      sx={{
        width: "200px",
        borderRadius: "50px",
        textTransform: "capitalize",
        fontWeight: 600,

        padding: "11px 15px",
        fontSize: "1rem",

        cursor: "pointer",
        transition: "transform 0.2s", // Added transition property for smooth animation

        "&.MuiButton-root:hover": { bgcolor: "primary" },
      }}
      startIcon={<>{icon}</>}
    >
      {text}
    </Button>
  );
};
const CustomInputField = (value, onChange) => {
  return (
    <TextField
      InputProps={{ sx: { borderRadius: 30, padding: "-5px", width: "100%" } }}
      id="outlined-basic"
      variant="outlined"
      placeholder="Enter Email"
      style={{ width: 350 }}
    />
  );
};
