import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../../src/assets/img/logo.png";
import { WEBSITE_NAME } from "../data";
import { useNavigate } from "react-router-dom";
import { sendEmail } from "../utils/SendEmail";
const drawerWidth = 240;
//const navItems = ['Services', 'Benefits', 'Contact'];

const NavButtons = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("userInfo"); // Get userInfo from localStorage
  const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;
  return (
    <Box display="flex" flexDirection="row">
      <Box
        sx={{
          display: { xs: "none", md: "flex", flexShrink: "1" },
          alignItems: "center",
        }}
      >
        <HashLink smooth to="/#home" style={{ textDecoration: "none" }}>
          <Button
            //href="#home"x
            color="primary"
            sx={{ margin: "5px 20px" }}
          >
            Home
          </Button>
        </HashLink>

        <HashLink smooth to="/#services" style={{ textDecoration: "none" }}>
          <Button
            //href="#services"
            color="primary"
            sx={{ margin: "5px 20px" }}
          >
            Services
          </Button>
        </HashLink>
        <HashLink smooth to="/#benefits" style={{ textDecoration: "none" }}>
          <Button
            //href="#benefits"
            color="primary"
            sx={{ margin: "5px 20px" }}
          >
            Benefits
          </Button>
        </HashLink>
        <HashLink smooth to="/#contact" style={{ textDecoration: "none" }}>
          <Button
            //href="#contact"
            color="primary"
            sx={{ margin: "5px 20px" }}
          >
            Contact
          </Button>
        </HashLink>
        <Button
          color="themeYellowLight"
          variant="contained"
          sx={{
            color: "white",
            fontWeight: "600",
            borderRadius: "50px",
            // textTransform: "capitalize",
            height: "fit-content",
            padding: "8px 24px",
          }}
          size="small"
          onClick={() => {
            if (parsedUserInfo) {
              navigate("user/profile");
            } else {
              setIsLoggedIn(!isLoggedIn);
            }
          }}
        >
          Login
        </Button>
        {isLoggedIn && !parsedUserInfo && <OneTapLogin />}
      </Box>
    </Box>
  );
};
function NavbarWithDrawer() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  function toggleDrawer() {
    setOpenDrawer(!openDrawer);
  }

  return (
    <Box>
      <AppBar
        sx={{ margin: "0px", position: "fixed", height: "65px" }}
        color=""
      >
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            <HashLink smooth to="/#home" style={{ textDecoration: "none" }}>
              <Stack
                direction="row"
                spacing={1}
                display={"flex"}
                justifyItems={"center"}
                alignItems={"center"}
              >
                <img src={logo} alt="logo big" height="60px" />
                <Typography
                  variant="h5"
                  color={"primary"}
                  align="center"
                  justifyContent={"center"}
                >
                  {WEBSITE_NAME}
                </Typography>
              </Stack>
            </HashLink>
          </Box>
          <Box
            display="flex"
            sx={{ flexGrow: 1, display: { xs: "block", md: "none" } }}
          >
            <HashLink smooth to="/#home" style={{ textDecoration: "none" }}>
              <Stack
                direction="row"
                spacing={1}
                display={"flex"}
                justifyItems={"center"}
                alignItems={"center"}
              >
                <img src={logo} alt="logo big" height="40px" />
                <Typography
                  variant="h6"
                  color={"primary"}
                  align="center"
                  justifyContent={"center"}
                >
                  {WEBSITE_NAME}
                </Typography>
              </Stack>
            </HashLink>
          </Box>
          <NavButtons />
          <IconButton
            color="inherit"
            onClick={toggleDrawer}
            sx={{ display: { sm: "block", md: "none" } }}
          >
            {/* <Menu /> */}
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          anchor="left"
          open={openDrawer}
          onClose={toggleDrawer}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Typography
            variant="h6"
            margin="12px 0px"
            color={"primary"}
            textAlign="center"
          >
            {WEBSITE_NAME}
          </Typography>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton href="/#home" onClick={toggleDrawer}>
                <ListItemText primary="Home" sx={{ textAlign: "center" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/#services" onClick={toggleDrawer}>
                <ListItemText primary="Services" sx={{ textAlign: "center" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/#benefits" onClick={toggleDrawer}>
                <ListItemText primary="Benefits" sx={{ textAlign: "center" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/#contact" onClick={toggleDrawer}>
                <ListItemText primary="Contact" sx={{ textAlign: "center" }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}

export default NavbarWithDrawer;
const OneTapLogin = () => {
  const navigate = useNavigate(); // Create navigate function
  const [userInfo, setUserInfo] = useState(null);
  useGoogleOneTapLogin({
    onError: (error) => console.log(error),
    onSuccess: (response) => setUserInfo(response),
    googleAccountConfigs: {
      client_id:
        "1050913679320-ame7hulg0dlj7rcq7uqpn2phjed46nnj.apps.googleusercontent.com",
    },
  });
  useEffect(() => {
    if (userInfo) {
      console.log(userInfo);
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      sendEmail();
      navigate("/user/profile");
    
    }
  }, [userInfo]);

  return <></>;
};
