import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import BasicTabs from "./Tabs";
export default function UserProfilePage() {
  useEffect(() => {}, []);
  return (
    <Box>
      <Typography variant="h3" fontWeight={900} marginY={2}>
        User Profile
      </Typography>
      <BasicTabs />
    </Box>
  );
}
