import React, { useState } from "react";
import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
import { Button, Typography, Stack, IconButton, Switch } from "@mui/material";
import { red } from "@mui/material/colors";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ConfirmationDialog } from "./confirmationDialog";
import { WEBSITE_NAME } from "../../data";
// import { WEBSITE_NAME } from "../../constants";


export default function Teams() {
  const [isAddingMember, setIsAddingMember] = useState(false);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "firstName",
      headerName: "Member Name",
      headerClassName: "super-app-theme--header",
      width: 150,
      editable: true,
    },

    {
      field: "phoneNumber",
      headerName: "Phone Number",
      headerClassName: "super-app-theme--header",
      type: "phoneNumber",
      width: 110,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      width: 110,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 110,
      editable: true,
      renderCell: () => {
        return <Switch />;
      },
    },
    {
      field: "edit",
      headerName: "Actions",
      align: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} style={{ margin: "0px 8px" }}>
            {/* <Switch></Switch> */}
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                // handleEditClick(params.row);
              }}
              sx={{
                border: `1px solid ${red["A700"]}`,
                color: red["A700"],
                backgroundColor: red[50],
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const rows = [
    // { id: 4, email: "Stark", firstName: "Arya", phoneNumber: 9111505782 },
    // { id: 5, email: "Targaryen", firstName: "Daenerys", phoneNumber: null },
    // { id: 6, email: "Melisandre", firstName: null, phoneNumber: 150 },
    // { id: 7, email: "Clifford", firstName: "Ferrara", phoneNumber: 44 },
    // { id: 8, email: "Frances", firstName: "Rossini", phoneNumber: 36 },
    // { id: 9, email: "Roxie", firstName: "Harvey", phoneNumber: 65 },
  ];

  return (
    <>
      {/* <h1>jcdijdicj</h1> */}
      <Box sx={{ marginTop: 2, position: "relative" }}>
        {/* <Typography>Members</Typography> */}
        <Typography
          variant="h6"
          fontWeight={700}
          color="text.secondary"
          // gutterBottom
          marginY={1}
        >
          Family Members
        </Typography>
        <Stack direction="row" justifyContent="space-between" marginY={1.5}>
          <Typography variant="body2" color="text.secondary" fontWeight={400}>
          View and manage your family portfolio.
          </Typography>
          <Button variant="contained" disabled>
            Add member
          </Button>
        </Stack>
        <Box
          sx={{
            height: 300,
            width: "100%",
            "& .super-app-theme--header": {
              //   backgroundColor: "rgba(255, 7, 0, 0.55)",
              fontWeight: "9000",
              fontSize: "1rem",
            },
          }}
        >
          {/* <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            slots={{
              noRowsOverlay: () => {
                return(
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: "darkgray",
                    }}
                  >
                    Comming Soon
                  </Typography>
                </Box>);
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          /> */}
        </Box>
      </Box>

      <ConfirmationDialog
        title="Work in Progress"
        content={
          <Typography variant="body2">
            <b>🚧 Feature: Add Team Member</b>
            <br />
            <br />
            We're in the process of creating a robust feature that will allow
            you to seamlessly add team members and manage their licenses within
            our app.
            <br />
            This functionality will revolutionize collaboration and streamline
            user access. Stay updated - we'll be releasing this soon!
            <br />
            <br />
            Team
            <br />
            <b>{WEBSITE_NAME}</b>
          </Typography>
        }
        open={isAddingMember}
        onClose={() => setIsAddingMember(false)}
        hideCancelButton
        confirmButtonText="Okay"
        onConfirmation={() => setIsAddingMember(false)}
      />
    </>
  );
}
