import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserInfo from "../components/UserInfo";
import Teams from "../components/Teams";
import Accounts from "../components/Accounts";
import { useState } from "react";
import { useEffect } from "react";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

 
  const [isLoading, setIsLoading] = useState(false);
  // const { user } = useSelector((store) => store.user);
  const getUser = async () => {
    // let { data } = await customFetch.get(
    //   `/user?email=${user.user.email}`,
    //   authHeader()
    // );
    // dispatch(updateUser({ user: data }));
    // setIsLoading(false);
  };

  useEffect(() => {
    // getUser();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="General"
            sx={{ fontWeight: "600", fontSize: "1rem" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Members"
            sx={{ fontWeight: "600", fontSize: "1rem" }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UserInfo />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Teams />
      </CustomTabPanel>
    </Box>
  );
}
