import React from "react";

import Hero from "../components/hero";
import Stats from "../components/stats";
import Services from "../components/services";
import Compounding from "../components/compounding";
import Benefits from "../components/benefits";
import Contact from "../components/contact";
import Footer from "../global/components/Footer";
// import Footer from "../components/footer";

function HomePage() {
  return (
    <div id="home">
      <Hero />
      <Stats />
      <Services />
      <Compounding />
      <Benefits />
      <Contact />   
    </div>
  );
}

export default HomePage;
