//@ts-check

import React, { useEffect } from "react";
import { Box, Typography, Container } from "@mui/material";
import { UPDATE_DATE, WEBSITE_NAME } from "../../data";

export default function AcceptableUsePage() {
  const name = WEBSITE_NAME;
  const effectiveDate = UPDATE_DATE;

  return (
    <>
      <Box marginTop={16} marginBottom={16}>
        <Container maxWidth="md">
          <Typography
            fontWeight={700}
            variant="h1"
            align="center"
            marginTop={4}
          >
            Acceptable Use Policy
          </Typography>
          <Typography variant="body1" color="grey" marginTop={4}>
            This acceptable use policy covers the products, services, and
            technologies (collectively referred to as the “Products”) provided
            by
            {name} under any ongoing agreement. It's designed to protect us, our
            customers, and the general Internet community from unethical,
            irresponsible, and illegal activity.
            <br />
            <br />
            {name} customers found engaging in activities prohibited by this
            acceptable use policy can be liable for service suspension and
            account termination. In extreme cases, we may be legally obliged to
            report such customers to the relevant authorities.
            <br />
            <br />
            This policy was last reviewed on {effectiveDate}.
          </Typography>
          <Typography fontWeight={700} variant="h5" marginTop={4}>
            Fair use
          </Typography>
          <Typography variant="body1" color="grey" marginTop={4}>
            We provide our facilities with the assumption your use will be
            “business as usual”, as per our offer schedule. If your use is
            considered to be excessive, then additional fees may be charged, or
            capacity may be restricted.
            <br />
            <br />
            We are opposed to all forms of abuse, discrimination, rights
            infringement, and/or any action that harms or disadvantages any
            group, individual, or resource. We expect our customers and, where
            applicable, their users (“end-users”) to likewise engage our
            Products with similar intent.
          </Typography>
          <Typography fontWeight={700} variant="h5" marginTop={4}>
            Customer accountability
          </Typography>
          <Typography variant="body1" color="grey" marginTop={4}>
            We regard our customers as being responsible for their own actions
            as well as for the actions of anyone using our Products with the
            customer's permission. This responsibility also applies to anyone
            using our Products on an unauthorized basis as a result of the
            customer's failure to put in place reasonable security measures.
            <br />
            <br />
            By accepting Products from us, our customers agree to ensure
            adherence to this policy on behalf of anyone using the Products as
            their end users. Complaints regarding the actions of customers or
            their end-users will be forwarded to the nominated contact for the
            account in question.
            <br />
            <br />
            If a customer — or their end-user or anyone using our Products as a
            result of the customer — violates our acceptable use policy, we
            reserve the right to terminate any Products associated with the
            offending account or the account itself or take any remedial or
            preventative action we deem appropriate, without notice. To the
            extent permitted by law, no credit will be available for
            interruptions of service resulting from any violation of our
            acceptable use policy.
          </Typography>

          <Typography fontWeight={700} variant="h5" marginTop={4}>
            Prohibited activity
          </Typography>
          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Copyright infringement and access to unauthorized material
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Our Products must not be used to transmit, distribute or store any
            material in violation of any applicable law. This includes but isn't
            limited to:
            <br />
            <br />
            <ol>
              <li>
                any material protected by copyright, trademark, trade secret, or
                other intellectual property right used without proper
                authorization, and
              </li>
              <li>
                any material that is obscene, defamatory, constitutes an illegal
                threat, or violates export control laws.
              </li>
            </ol>
            <br />
            The customer is solely responsible for all material they input,
            upload, disseminate, transmit, create or publish through or on our
            Products, and for obtaining legal permission to use any works
            included in such material.
          </Typography>

         

          
          <Typography fontWeight={700} variant="h6" marginTop={4}>
            Unauthorized use of {name} property
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            We prohibit the impersonation of {name}, the representation of a
            significant business relationship with {name}, or ownership of any{" "}
            {name} property (including our Products and brand) for the purpose
            of fraudulently gaining service, custom, patronage, or user trust.
          </Typography>
          <Typography fontWeight={700} variant="h5" marginTop={4}>
            About this policy
          </Typography>
          <Typography variant="body1" color="grey" marginTop={4}>
            This policy outlines a non-exclusive list of activities and intent
            we deem unacceptable and incompatible with our brand.
            <br />
            <br />
            We reserve the right to modify this policy at any time by publishing
            the revised version on our website. The revised version will be
            effective from the earlier of:
            <br />
            <br />
            <ul>
              <li>
                the date the customer uses our Products after we publish the
                revised version on our website; or
              </li>
              <li>
                30 days after we publish the revised version on our website.
              </li>
            </ul>
          </Typography>
        </Container>
      </Box>
    </>
  );
}
