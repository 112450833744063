//@ts-check

import React from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LinearProgress from '@mui/material/LinearProgress';


/**
 * 
 * @param {Object} props 
 * @param {boolean} props.open
 * @param {() => void} [props.onBack]
 * @param {() => void} props.onClose
 * @param {() => void} props.onConfirmation
 * @param {string} props.title
 * @param {string | JSX.Element} props.content
 * @param {string} [props.cancelButtonText]
 * @param {string} [props.confirmButtonText]
 * @param {"inherit" | "error" | "primary" | "secondary" | "success" | "info" | "warning"} [props.cancelButtonColor]
 * @param {"inherit" | "error" | "primary" | "secondary" | "success" | "info" | "warning"} [props.confirmButtonColor]
 * @param {boolean} [props.workInProgress]
 * @param {boolean} [props.disableMaxWidth]
 * @param {boolean} [props.disableConfirmButton]
 * @param {boolean} [props.hideConfirmButton]
 * @param {boolean} [props.hideCancelButton]
 * @returns 
 */

export function ConfirmationDialog({
    open,
    onClose,
    onBack,
    onConfirmation,
    title,
    content,
    cancelButtonText,
    confirmButtonText,
    cancelButtonColor,
    confirmButtonColor,
    workInProgress,
    disableConfirmButton,
    disableMaxWidth,
    hideConfirmButton,
    hideCancelButton,

}){
    return (
        <Dialog
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '16px',
            maxWidth: disableMaxWidth ? 'initial': '600px',
            minWidth: {
              sm: '600px',
              xs: 'calc(100% - 40px)',
            }
          }
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        {
          workInProgress ? <LinearProgress/> : null
        }
        <DialogContent dividers={true}>
          {content}
        </DialogContent>
        <DialogActions sx={{
          paddingX: 3,
          paddingY: 2,
        }}>
          { !hideCancelButton && 
            <Button
              onClick={onBack ? onBack : onClose}
              variant="outlined"
              color={cancelButtonColor ? cancelButtonColor : 'error'}
              disabled={workInProgress}
            >
              {cancelButtonText ? cancelButtonText : 'No'}
            </Button>
          }

          { !hideConfirmButton && 
            <Button 
              onClick={onConfirmation}
              variant="contained"
              disabled={workInProgress || disableConfirmButton}
              color={confirmButtonColor ? confirmButtonColor : 'primary'}
              // startIcon={workInProgress ? <CircularProgress size='small'/> : null}
            >
              {confirmButtonText ? confirmButtonText : 'Yes'}
            </Button>
          }
        </DialogActions>
      </Dialog>
    )
}