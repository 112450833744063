import { Box, Container, Typography } from "@mui/material";
import React from "react";
import img from "../../assets/img/invest.jpeg";
import { WEBSITE_NAME } from "../../data";
export default function WhyMoneyDost() {
  return (
    <>
      <Container maxWidth="md" sx={{ marginTop: 16 , marginBottom:16}}>
        <Typography fontWeight={700} variant="h1" align="center" marginTop={4}>
          {WEBSITE_NAME} Services
        </Typography>
        <Box
          component="img"
          src={img}
          alt={`Guidelines for Using ${WEBSITE_NAME}`}
          sx={{
            display: "block",
            width: "80%",
            height: "70%",
            margin: "auto",
            marginTop: 4,
            marginBottom: 6,
            borderRadius: "5px",
          }}
        ></Box>
        <Typography variant="body1" color="grey" marginTop={2}>
          Signing up for a membership typically includes the following benefits.
        </Typography>
        <Typography fontWeight={700} variant="h6" marginTop={2}>
          Exclusive weekly and monthly updates
        </Typography>
        <ul>
          <Typography variant="body1" color="grey" marginTop={2}>
            <li>
              Enjoy weekly and monthly portfolio summary emails, exclusively
              available to members.
            </li>
          </Typography>
        </ul>

        <Typography fontWeight={700} variant="h6" marginTop={2}>
          Performance Updates
        </Typography>
        <ul>
          <Typography variant="body1" color="grey" marginTop={2}>
            <li>
              Receive periodic emails with updates on the performance of
              specific funds in your portfolio, highlighting significant changes
              and milestones.
            </li>
          </Typography>
        </ul>

        <Typography fontWeight={700} variant="h6" marginTop={2}>
          Regulatory and Compliance Updates
        </Typography>
        <ul>
          <Typography variant="body1" color="grey" marginTop={2}>
            <li>
              Stay informed with emails about important regulatory changes,
              compliance updates, and any required actions to meet new
              requirements.
            </li>
          </Typography>
        </ul>
        <Typography fontWeight={700} variant="h6" marginTop={2}>
          Asset Allocation Strategy
        </Typography>
        <ul>
          <Typography variant="body1" color="grey" marginTop={2}>
            <li>
              A well-diversified plan will not outperform the top asset class in
              any given year, but over time it may be one of the most effective
              ways to realize your long-term goals.
            </li>
          </Typography>
        </ul>

        <Typography fontWeight={700} variant="h6" marginTop={2}>
          PE Based Model
        </Typography>
        <ul>
          <Typography variant="body1" color="grey" marginTop={2}>
            <li>
              The price/earnings ratio (P/E) is the best investment valuation
              indicator. Our focus is to invest more in equity when PE is low
              and vice versa.
            </li>
          </Typography>
        </ul>

        <Typography fontWeight={700} variant="h6" marginTop={2}>
          Asset Selection Strategy
        </Typography>
        <ul>
          <Typography variant="body1" color="grey" marginTop={2}>
            <li>
              Our focus is to actively manage all asset classes to get the best
              rewards while taking minimal risk.
            </li>
          </Typography>
        </ul>

        <Typography fontWeight={700} variant="h6" marginTop={2}>
          Tailor Made Investing
        </Typography>
        <ul>
          <Typography variant="body1" color="grey" marginTop={2}>
            <li>
              Our portfolio recommendations are according to investor risk
              profile, time horizon of investment, and outflows required at
              different stages of the life-cycle.
            </li>
          </Typography>
        </ul>
      </Container>
    </>
  );
}
