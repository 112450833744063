import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import { Stack, Typography } from "@mui/material";
export default function NoAccess() {
  return (
    <Stack
      sx={{ height: "100%", width: "100%" }}
      justifyContent={"center"}
      direction="column"
      alignItems={"center"}
    >
      <LockIcon sx={{ fontSize: "8rem", opacity: 0.5 }} />
      <Typography variant="h5">No Current Investments</Typography>
      <Typography
        variant="subtitle2"
        mb={6}
        paddingX={"16vw"}
        textAlign={"center"}
        mt={2}
      >
        You do not have any investments with us. Please contact your respective
        Wealth Manager for assistance. If you do not have a Wealth Manager,
        please reach out to us at <strong>+91 90044 24074.</strong>
      </Typography>
    </Stack>
  );
}
