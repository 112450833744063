import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import theme from "../../theme";
import { alpha } from "@mui/material/styles";
export default function FooterLinkBtn({ text, path }) {
  return (
    <Box
      component="li"
      sx={{
        fontSize: "13.3px",
        // fontWeight: 500,
        listStyle: "none",
        cursor: "pointer",
        padding: "10px 0px",
        borderRadius: "10px",
        textAlign: { xs: "center", sm: "left" },
      }}
    >
      <Box
        component={Link}
        to={path && path}
        sx={{
          textDecoration: "none",
          padding: "10px 14px",
          borderRadius: "10px",
          color: "#171e30",
          "&:hover": {
            // background: "#45e79914",
            background: alpha(theme.palette.primary.main, 0.2),

            color: theme.palette.primary.main,
          },
          position: "relative",
          left: { md: "-10px", sm: "0px", xs: "0px" },
        }}
        // onClick={() =>
        //   path == "/blogs" && window.open("https://sheetwa.com/blogs", "_blank")
        // }
      >
        {text}
        <Link to={`${path}`} style={{ display: "none" }}>
          linkToStore
        </Link>
      </Box>
    </Box>
  );
}
