import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import { benefits } from "../../src/data";
import { motion } from "framer-motion";
const data = benefits;
function Benefits() {
  
  return (
    <Box id="benefits" style={{ scrollMarginTop: "60px" }}>
      <Container maxWidth="lg">
        <Box sx={{ pt: 10, pb: 5 }}>
          <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>
            Benefits with us
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: "text.secondary", mt: 2 }}
          >
            Our approach is centered around helping our clients achieve
            long-term financial success.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {data.map((item, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent={"center"}
                alignItems="center"
                marginBottom={{ xs: 2, md: 4 }}
                component={motion.div}
                initial={{
                  scale: 0.5 ,
                  x:
                    item.title === "Unbiased Advise" ||
                    item.title === "Goals Driven"
                      ? -90
                      : 90,
                }}
                whileInView={{
                  
                  opacity: 1,
                  scale: 1,
                  x:
                    item.title === "Unbiased Advise" ||
                    item.title === "Goals Driven"
                      ? 0
                      : 0,
                }}
                transition={{ duration: 1.3}}
                viewport={{ once: true }}
              >
                <Box component="img" src={item.image} width="250px"></Box>
                <Box>
                  <Typography
                    variant={"h6"}
                    gutterBottom
                    sx={{ fontWeight: 700, marginTop: "20px" }}
                    align="center"
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}


export default Benefits;
