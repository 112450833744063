import React from "react";

export default function PageLoader() {
  return (
    <div
      id="page-loader"
      class="loader-overlay"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(255, 255, 255)",
        zIndex: 9999,
      }}
    >
      <span class="loader-in"></span>
    </div>
  );
}
