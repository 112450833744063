import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const userInfo = localStorage.getItem('userInfo'); // Get userInfo from localStorage
  const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;
  if (!parsedUserInfo) {
    return <Navigate to="/" />;
  }
  return children;
}
