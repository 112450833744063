import React from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import FooterColumn from "./footerColumn";
// import { PLUGIN_URL, WEBSITE_NAME } from "../../constants";
import logo from "../../assets/img/logo.png";
// import { faChrome } from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import { metaHeaders } from "../../headers";

import { WEBSITE_NAME } from "../../data";

export default function Footer() {
  const handleNavigation = (url) => {
    window.open(url, "_blank");
  };
  const socialLinks = [
    {
      label: "Instagram",
      icon: <InstagramIcon />,
      url: "https://www.instagram.com/sheetwa_official/",
    },
    { label: "X", icon: <XIcon />, url: "https://twitter.com/SheetWA" },
    {
      label: "Facebook",
      icon: <FacebookOutlinedIcon />,
      url: "https://www.facebook.com/SheetWA.official/",
    },
    {
      label: "LinkedIn",
      icon: <LinkedInIcon />,
      url: "https://www.linkedin.com/company/sheetwa/",
    },
  ];
  const footerData = [
    // {
    //   title: "Why SheetWA",

    //   links: landingPageData
    //     .map((data, idx) => {
    //       return {
    //         linkName: `${data.label} `,
    //         path: `/${data.headers.canonical}/`,
    //       };
    //     })
    //     .concat({
    //       linkName: "Pricing",
    //       path: "/pricing/",
    //     }),
    // },

    {
      title: "Resources",
      links: [
        {
          linkName: "Getting started",
        },
        {
          linkName: "FAQs",
        },
        {
          linkName: "Blogs",
        },
        {
          linkName: "Tutorials",
        },
        {
          linkName: "Our servies",
          path: "services",
        },
        // {
        //   linkName: "Career",
        //   path: "/career/",
        // },
      ],
    },
    {
      title: "Support",
      links: [
        {
          linkName: "Contact",
          path: "/support/",
        },

        {
          linkName: "Guidelines",
          path: "/guidelines/",
        },

        // {
        //   linkName: "Cancellation",
        //   // path: "/cancellation-policy/",
        // },
      ],
    },
    // {
    //   title: "Compare",
    //   links: comparesionPageDetails.map((data) => {
    //     return {
    //       linkName: `${data.label} Alternative`,
    //       path: `/${data.headers.canonical}/`,
    //     };
    //   }),
    // },
    {
      title: "Policies",
      links: [
        {
          linkName: "Cookie policy",
          path: "/cookie/",
        },
        {
          linkName: "Privacy policy",
          path: "/privacy-policy/",
        },

        {
          linkName: "Terms of Serivce",
          path: "/terms-of-service/",
        },
        {
          linkName: "Acceptable policy",
          path: "/acceptablePolicy/",
        },
      ],
    },
  ];

  return (
    <Box sx={{ backgroundColor: "rgb(234 239 255 / 25%)" }}>
      <Container>
        <Stack
          direction={{ sm: "column", md: "row" }}
          sx={{ marginTop: "5vh" }}
        >
          <Box
            sx={{
              width: { sm: "100%", md: "42%" },
              padding: { sm: "60px 40px 10px 10px", md: "60px 40px 60px 10px" },
            }}
          >
            {/* <Typography></Typography> */}
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "initial" }}
            >
              <Box
                component="img"
                src={logo}
                height="60px"
                // width="60px"
                alt="MoneyDost"
              ></Box>
              <Typography
                variant="h5"
                color={"primary"}
                align="center"
                justifyContent={"center"}
              >
                {WEBSITE_NAME}
              </Typography>
            </Stack>

            <Box
              sx={{
                fontSize: "16px",
                // fontWeight: 500,
                listStyle: "none",
                cursor: "pointer",
                paddingBottom: "2px",
                borderRadius: "10px",
                color: "#171e30",
                textAlign: { xs: "center", sm: "initial" },
              }}
            >
              Supercharge wealth management
            </Box>
            <SocialLinks
              socialLinks={socialLinks}
              handleNavigation={handleNavigation}
            />
            <Typography variant="body2" mt={0.5}>
              © Copyright {new Date().getFullYear()}. {WEBSITE_NAME}. SS
              Wealthserv Technologies Pvt Ltd All rights reserved.
            </Typography>

            {/* <CustomButtonFooter /> */}
          </Box>
          <Box
            sx={{
              width: { sm: "100%", md: "78%" },
              display: "flex",
              flexDirection: { sm: "column", md: "row", xs: "column" },
              paddingLeft: { sm: "10px", md: "40px" },
              paddingTop: "60px",
              paddingBottom: "60px",
              flexWrap: "wrap",
            }}
          >
            {footerData.map((i, idx) => {
              // console.log(i);
              return <FooterColumn title={i.title} links={i.links} key={idx} />;
            })}
          </Box>
        </Stack>
        <Stack>
          <Box
            sx={{
              backgroundColor: "gray",
              height: "2px",
              left: "-2px",
              border: "2px solid #eee",
            }}
          ></Box>

          <Typography
            color="GrayText"
            mb={4}
            mt={2}
            variant="body2"
            textAlign={"center"}
          >
            Disclaimer: Investments are subject to market risks.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
const SocialLinks = ({ socialLinks, handleNavigation }) => {
  return (
    <>
      {socialLinks.map(({ label, icon, url }) => (
        <IconButton
          key={label}
          aria-label={label}
          color="primary"
          // onClick={() => handleNavigation(url)}
          size="large"
        >
          {icon}
        </IconButton>
      ))}
    </>
  );
};
