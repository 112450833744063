import emailjs from "@emailjs/browser";
export const sendEmail = async () => {
  const userInfo = localStorage.getItem("userInfo"); // Get userInfo from localStorage
  const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;
  var templateParams = {
    user_name: parsedUserInfo.name || parsedUserInfo.given_name,
    user_email: parsedUserInfo.email,
  };
  try {
    const data = await emailjs.send(
      "service_dh0qt6c",
      "template_186nqyw",
      templateParams,
      "5x2Pc9Cl39ZV0JTCe"
    );
    console.log("email resp >>>>>>>>>>>>>>>", data);
  } catch (error) {
    console.log(error);
  }
};
