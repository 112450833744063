import React from "react";
import NavbarWithDrawer from "../components/NavbarWithDrawer";
import { Routes } from "react-router-dom";
import HomePage from "./HomePage";
import PrivacyPolicyPage from "./footer/PrivacyPolicyPage";
import TermsOfServicePage from "./footer/TermsOfServicePage";
import CancellationPage from "./footer/CancellationPage";
import CookiePolicyPage from "./footer/CookiePolicyPage";
import AcceptableUsePage from "./footer/AcceptableUsePage";
import Guidelines from "../global/components/Guidelines";
import StepByStepGuidePage from "./footer/StepByStepGuidePage";
import SupportPage from "../global/components/SupportPage";
import { Route } from "react-router-dom";
import Footer from "../global/components/Footer";
import WhyMoneyDost from "./footer/WhyMoneyDost";

export default function PublicRoutes() {
  return (
    <>
      <NavbarWithDrawer />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="terms-of-service" element={<TermsOfServicePage />} />
        <Route path="services" element={<WhyMoneyDost />} />
        {/* <Route path="cancellation-policy" element={<CancellationPage />} /> */}
        <Route path="cookie" element={<CookiePolicyPage />} />
        <Route path="acceptablePolicy" element={<AcceptableUsePage />} />
        <Route path="support" element={<SupportPage />} />
        <Route path="guide" element={<StepByStepGuidePage />} />
        <Route path="guidelines" element={<Guidelines />} />
      </Routes>
      <Footer />
    </>
  );
}
