//@ts-check

import React from "react";

import { Box, Container, Link, Typography } from "@mui/material";
import { useEffect } from "react";

import { WEBSITE_NAME } from "../../data";

export default function SupportPage({ disableTopMargin }) {
  const name = WEBSITE_NAME;
  // const web = ' www.sheetstowhatsapp.com';
  // const effectiveDate = '29 July 2023';

  return (
    <>
      <Box marginTop={disableTopMargin ? 0 : 16} marginBottom={16}>
        <Container maxWidth="md">
          {disableTopMargin ? (
            <Typography
              variant="h3"
              fontWeight={600}
              marginY={3}
              align="center"
            >
              Customer Support
            </Typography>
          ) : (
            <Typography
              fontWeight={700}
              variant="h1"
              align="center"
              marginTop={4}
            >
              Customer Support
            </Typography>
          )}

          <Typography variant="body1" color="grey" marginTop={4}>
            At {name}, we value your satisfaction, and our support team is
            dedicated to ensuring that you have a pleasant experience with us.
            We look forward to assisting you and addressing any concerns you may
            have. Your feedback is crucial to us as we continuously strive to
            improve our services to meet your needs. Thank you for choosing{" "}
            {name}, and we are here to support you every step of the way.
          </Typography>
          <Typography variant="body1" color="grey" marginTop={4}>
            We are committed to providing the best customer support and
            assistance to ensure your experience with our products and services
            is seamless and satisfying. If you have any queries, concerns, or
            need assistance with any aspect of our website.If you have
            any queries, concerns, or need assistance with any aspect of our
            website contact us at   +91 90044 24074
          </Typography>
        </Container>
      </Box>
    </>
  );
}
