import React, { Suspense } from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import { Route, Routes } from "react-router-dom";
import PageLoader from "../global/components/PageLoader";
// import Invoice from "./pages/Invoice";
import ReferAndEarn from "./pages/ReferAndEarn";
import UserProfilePage from "./pages/UserProfilePage";
import Guidelines from "../global/components/Guidelines";
import SupportPage from "../global/components/SupportPage";
import NoAccess from "./components/NoAccess";
const NavbarDashBoard = React.lazy(() =>
  import("./components/NavbarDashBoard")
);
const Features_I = React.lazy(() => import("./pages/Features_i"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
// const MsgReport = React.lazy(() => import("./pages/MsgReport"));
export default function PrivateRoutes() {
  return (
    <>
      <ProtectedRoute>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route path="/" element={<NavbarDashBoard />}>
              <Route path="profile" element={<UserProfilePage />} />
              <Route path="reports" element={<NoAccess />} />
              <Route path="portfolio" element={<NoAccess />} />
              <Route path="rewards" element={<NoAccess />} />

              <Route
                path="support"
                element={<SupportPage disableTopMargin />}
              />

              <Route path="dashboard" element={<NoAccess />} />
            </Route>
          </Routes>
        </Suspense>
      </ProtectedRoute>
    </>
  );
}
