import { Container, Typography, Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { WEBSITE_NAME } from "../../data";
import img from "../../assets/img/invest.jpeg";
export default function Guidelines({ disableTopMargin }) {
  console.log(disableTopMargin);

  return (
    <>
      <Box marginTop={disableTopMargin ? 0 : 16} marginBottom={16}>
        <Container maxWidth="md">
          {disableTopMargin ? (
            <Typography
              variant="h3"
              fontWeight={600}
              marginY={3}
              align="center"
            >
              Guidelines for Using {WEBSITE_NAME}
            </Typography>
          ) : (
            <Typography
              fontWeight={700}
              variant="h1"
              align="center"
              marginTop={4}
            >
              Guidelines for Using {WEBSITE_NAME}
            </Typography>
          )}

          <Typography variant="body1" color="grey" marginTop={4}>
            As you get started, it's essential to understand some critical
            guidelines to ensure your number remains active and avoids potential
            blocks by WhatsApp. Adhering to these guidelines will help you in a
            seamless and productive experience with our tool.
          </Typography>
          <Box
            component="img"
            src={img}
            alt={`Guidelines for Using ${WEBSITE_NAME}`}
            sx={{
              display: "block",
              width: "80%",
              height: "70%",
              margin: "auto",
              marginTop: 4,
              marginBottom: 6,
              borderRadius: "5px",
            }}
          ></Box>

          <Stack direction="row" alignItems="center" gap="16px" marginTop={4}>
            <Box
              sx={{
                background: true ? "#45e799" : "#e74573",
                height: "26px",
                width: "26px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100%",
                padding: "2px",
              }}
            >
              {true ? (
                <DoneIcon sx={{ color: "white" }} />
              ) : (
                <CloseIcon sx={{ color: "white" }} />
              )}
            </Box>
            <Typography fontWeight={700} variant="h5" marginTop={0}>
              DOs
            </Typography>
          </Stack>

          {/* <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Your Research
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Understand the basics of mutual funds and stock investments.
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Research different funds and stocks before investing.
          </Typography>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Diversify Your Portfolio
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Spread your investments across various asset classes to reduce risk.
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Regularly review and rebalance your portfolio.
          </Typography>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Have a Clear Investment Plan
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Define your investment goals and risk tolerance.
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Set a realistic time horizon for your investments.
          </Typography>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Monitor Your Investments
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Regularly check the performance of your portfolio.
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Adjust your investments as needed to align with your goals.
          </Typography>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Consider Professional Advice
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Seek guidance from a financial advisor if you're unsure.
          </Typography>
          <Typography variant="body1" color="grey" marginTop={2}>
            Use resources provided by your investment platform, such as
            educational materials and tools.
          </Typography> */}
          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Your Research
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Understand the basics of mutual funds and stock investments.
              </li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>Research different funds and stocks before investing.</li>
            </Typography>
          </ul>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Diversify Your Portfolio
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Spread your investments across various asset classes to reduce
                risk.
              </li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>Regularly review and rebalance your portfolio.</li>
            </Typography>
          </ul>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Have a Clear Investment Plan
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>Define your investment goals and risk tolerance.</li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>Set a realistic time horizon for your investments.</li>
            </Typography>
          </ul>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Monitor Your Investments
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>Regularly check the performance of your portfolio.</li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Adjust your investments as needed to align with your goals.
              </li>
            </Typography>
          </ul>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Do Consider Professional Advice
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>Seek guidance from a financial advisor if you're unsure.</li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Use resources provided by your investment platform, such as
                educational materials and tools.
              </li>
            </Typography>
          </ul>

          <Stack direction="row" alignItems="center" gap="16px" marginTop={4}>
            <Box
              sx={{
                background: false ? "#45e799" : "#e74573",
                height: "26px",
                width: "26px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100%",
                padding: "2px",
              }}
            >
              {false ? (
                <DoneIcon sx={{ color: "white" }} />
              ) : (
                <CloseIcon sx={{ color: "white" }} />
              )}
            </Box>
            <Typography fontWeight={700} variant="h5" marginTop={0}>
              DON'Ts
            </Typography>
          </Stack>
          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Don't Invest Without a Plan
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>Avoid investing based on tips or rumors.</li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Don't chase after high returns without understanding the risks
                involved.
              </li>
            </Typography>
          </ul>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Don't Put All Your Money in One Investment
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Avoid concentrating your investments in a single stock or fund.
              </li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Diversification helps manage risk and protect your capital.
              </li>
            </Typography>
          </ul>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Don't Ignore Fees and Costs
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Be aware of management fees, transaction fees, and other costs.
              </li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Understand how these fees can impact your overall returns.
              </li>
            </Typography>
          </ul>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Don't Panic During Market Volatility
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Avoid making hasty decisions based on short-term market
                fluctuations.
              </li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Stick to your investment strategy and focus on long-term goals.
              </li>
            </Typography>
          </ul>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Don't Neglect Regular Contributions
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Consistently invest over time, even if the amounts are small.
              </li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Utilize automated investment plans to ensure regular
                contributions.
              </li>
            </Typography>
          </ul>

          <Typography fontWeight={700} variant="h6" marginTop={2}>
            Don't Overlook Tax Implications
          </Typography>
          <ul>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Understand the tax consequences of your investment decisions.
              </li>
            </Typography>
            <Typography variant="body1" color="grey" marginTop={2}>
              <li>
                Consider tax-efficient investment strategies to maximize your
                returns.
              </li>
            </Typography>
          </ul>

          <Typography variant="body1" color="grey" marginTop={4}>
            Thank you for choosing {WEBSITE_NAME}! Safe investing!
          </Typography>
        </Container>
      </Box>
    </>
  );
}
