import {
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Stack,
  Chip,
  Box,
} from "@mui/material";
import React, { useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import MailIcon from "@mui/icons-material/Mail";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CheckIcon from "@mui/icons-material/Check";
// import { useSelector } from "react-redux";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Diamond, MoneyOff } from "@mui/icons-material";
import PageLoader from "../../global/components/PageLoader";
export default function UserInfo() {
  // const { user } = useSelector((store) => store.user);
  // const accountsDetail = user?.user?.accountsDetail;
  const userInfo = localStorage.getItem("userInfo"); // Get userInfo from localStorage
  const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;
  useEffect(() => {
    console.log("user info>>>>>>>..", parsedUserInfo);
  }, [parsedUserInfo]);
  if (!parsedUserInfo) {
    return <PageLoader />;
  }

  return (
    <>
      <Divider />
      <Typography
        variant="h6"
        fontWeight={700}
        color="text.secondary"
        // gutterBottom
        marginY={2}
      >
        Information
      </Typography>
      <Paper sx={{ width: "99%", margin: "auto" }}>
        <DataRow
          Icon={AccountCircleIcon}
          name="Name"
          value={
            parsedUserInfo.given_name +
            " " +
            (parsedUserInfo.family_name ? parsedUserInfo.family_name : "")
          }
        />
        <Divider />
        <DataRow Icon={MailIcon} name="Email" value={parsedUserInfo.email} />
        <Divider />
        <DataRow Icon={LocalPhoneIcon} name="Phone" value={"-"} />
        <Divider />
        <DataRow Icon={CreditCardIcon} name="PAN" value={"-"} />
        <Divider />
        <DataRow Icon={CheckIcon} name="KYC status" value={"Not verified"} />
        {/* <Divider />
        <DataRow Icon={LocalPhoneIcon} name="Phone" value={user.user.phone} /> */}
      </Paper>

      {/* <Typography
        variant="h6"
        fontWeight={700}
        color="text.secondary"
        // gutterBottom
        marginY={2}
      >
        Linked Account
      </Typography> */}
      {/* <Paper sx={{ width: "99%", margin: "auto" }}>
        {accountsDetail?.length ? (
          accountsDetail.map((data, i) => {
            return (
              <>
                {i ? <Divider /> : null}
                <DataRow
                  isAccountsRow
                  Icon={LocalPhoneIcon}
                  name={"Phone"}
                  value={data.phone}
                  countryCode={data?.countryCode}
                  otherComponents={
                    <AccountsOtherComp
                      isPro={data?.isPro}
                      planName={data?.activePlan?.plan || null}
                      disabled={false}
                      phone={data.phone}
                    />
                  }
                  onClick={
                    !data?.isPro
                      ? () => {
                          window.open("/pricing", "_blank");
                        }
                      : null
                  }
                />
              </>
            );
          })
        ) : (
          <DataRow
            isAccountsRow
            disabled
            Icon={LocalPhoneIcon}
            name="Phone"
            value={"No Number"}
            otherComponents={
              <AccountsOtherComp isPro={false} disabled={true} />
            }
            onClick={() => {}}
          />
        )}
      </Paper> */}
    </>
  );
}

function AccountsOtherComp({ isPro, disabled, phone, planName }) {
  let Icon = isPro
    ? planName === "Basic"
      ? StarBorderPurple500Icon
      : Diamond
    : MoneyOff;

  return (
    <>
      <Chip
        icon={
          <Icon
            sx={{
              height: "20px",
            }}
          />
        }
        color={disabled ? "default" : "warning"}
        label={isPro ? planName?.toUpperCase() : "FREE"}
        style={{
          backgroundColor: isPro ? "#0f9d58" : undefined,
          width: "80px",
          fontWeight: "700",
        }}
      />
      {!isPro ? (
        <Button
          disabled={disabled}
          variant="contained"
          onClick={() => {
            window.open("https://sheetwa.com/pricing", "_blank");
          }}
        >
          Upgrade
        </Button>
      ) : (
        ""
      )}
    </>
  );
}

function DataRow({ Icon, name, value, otherComponents, countryCode }) {
  return (
    <Stack
      direction={"row"}
      sx={{
        BorderBottom: "4px solid gray",
        paddingX: 3,
        paddingY: 2,
        gap: "4rem",
      }}
    >
      <Stack direction={"row"} gap={"2.5rem"}>
        <Icon
          color="primary"
          sx={{
            width: "2.5rem",
            height: "2rem",
            display: { xs: "none", sm: "block" },
          }}
        />
        <Typography
          color="text.secondary"
          sx={{
            display: "block",
            width: "7.5rem",
          }}
        >
          {name}
        </Typography>
      </Stack>

      <Typography
        fontWeight={700}
        color="text.secondary"
        sx={{ width: name == "Phone" ? "13%" : "fit-content" }}
      >
        {countryCode ? `+${countryCode} ${value}` : value}
      </Typography>

      {otherComponents}
    </Stack>
  );
}
